import React from 'react';
import Layout from '../components/Layout';
import BannerIndex from '../components/BannerIndex';
import InfoIndex from '../components/InfoIndex';

const Index = () => {
  return (
    <div>
      <Layout 
        Banner={<BannerIndex />} 
        InfoSection={<InfoIndex />} 
      />
    </div>
  );
};

export default Index;
