import React, { useState } from 'react';

const InfoSection = () => {

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    request: '',
    message: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'Le prénom est requis';
    if (!formData.lastName) newErrors.lastName = 'Le nom est requis';
    if (!formData.email) newErrors.email = "L'email est requis";
    if (!formData.phone) newErrors.phone = 'Le téléphone est requis';
    if (!formData.request) newErrors.request = 'La demande est requise';
    if (!formData.message) newErrors.message = 'Le message est requis';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const emailRecipients = ["dalvarez016715@gmail.com", "also15@orange.fr", "mathieu.veglio@wanadoo.fr"];

    const emailBody = `
      Prénom: ${formData.firstName}\n
      Nom: ${formData.lastName}\n
      Email: ${formData.email}\n
      Téléphone: ${formData.phone}\n
      Demande: ${formData.request}\n
      Message: ${formData.message}
    `;

    // Simuler l'envoi d'email (Remplacez ceci par une intégration réelle si nécessaire)
    alert(`Email envoyé à: ${emailRecipients.join(', ')}\n\n${emailBody}`);

    setFormData({ firstName: '', lastName: '', email: '', phone: '', request: '', message: '' });
  };

  return (
    <section className="py-12 px-12 bg-white">
      <div className="container mx-auto text-center">
        {/* Formulaire */}
        <form className="p-6 mt-10 rounded-lg" onSubmit={handleSubmit}>
          <h3 className="text-[#C84C77] mb-5 font-bold">Contactez-nous</h3>

          <div className="mb-4">
            <label className="block mb-2 font-bold">Prénom *</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
            {errors.firstName && <span className="text-red-500 text-sm">{errors.firstName}</span>}
          </div>

          <div className="mb-4">
            <label className="block mb-2 font-bold">Nom *</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
            {errors.lastName && <span className="text-red-500 text-sm">{errors.lastName}</span>}
          </div>

          <div className="mb-4">
            <label className="block mb-2 font-bold">Email *</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
            {errors.email && <span className="text-red-500 text-sm">{errors.email}</span>}
          </div>

          <div className="mb-4">
            <label className="block mb-2 font-bold">Téléphone *</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
            {errors.phone && <span className="text-red-500 text-sm">{errors.phone}</span>}
          </div>

          <div className="mb-4">
            <label className="block mb-2 font-bold">Demande *</label>
            <select
              name="request"
              value={formData.request}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            >
              <option value="">-- Sélectionnez une option --</option>
              <option value="Information générale">Information générale</option>
              <option value="Inscription">Inscription</option>
              <option value="Sortie découverte">Sortie découverte</option>
            </select>
            {errors.request && <span className="text-red-500 text-sm">{errors.request}</span>}
          </div>

          <div className="mb-4">
            <label className="block mb-2 font-bold">Message *</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            ></textarea>
            {errors.message && <span className="text-red-500 text-sm">{errors.message}</span>}
          </div>

          <button
            type="submit"
            className="text-[#DD7C88] font-bold px-4 py-2 rounded hover:underline"
          >
            Envoyer
          </button>
        </form>
      </div>
    </section>
  );
};

export default InfoSection;
