import React, { useEffect, useState } from 'react';

const BannerEvenement = () => {
  const [parcours, setParcours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const baseURL = process.env.REACT_APP_API_BASE_URL || ""; // Utilise la variable d'environnement ou une valeur par défaut
    const groupes = [1, 2, 3];
    const groupTitles = {
      1: 'Parcours Sportif',
      2: 'Parcours Tourisme',
      3: 'Parcours FSGT',
    };

    Promise.all(
      groupes.map((groupe) =>
        fetch(`/get_parcours.php?groupe=${groupe}`)
          .then((response) => {
            if (!response.ok) {
              const errorMessage = `Erreur lors de la récupération des données pour le groupe ${groupe}: ${response.status} ${response.statusText}`;
              throw new Error(errorMessage);
            }
            return response.json();
          })
          .then((data) => data.map((item) => ({ ...item, groupTitle: groupTitles[groupe], group: groupe })))
      )
    )
      .then((allData) => {
        const mergedData = allData.flat(); // Combine les données des groupes
        setParcours(mergedData);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
  };

  const formatTime = (timeString) => {
    if (!timeString) return '';
    const [hours, minutes] = timeString.split(':');
    return `${parseInt(hours)}h${minutes}`;
  };

  if (loading) return <p>Chargement des données...</p>;
  if (error) return <p>Erreur : {error}</p>;

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4 flex-col sm:flex-col lg:flex-row justify-between content-start bg-[#DD7C88] py-12 px-12">
      {parcours.map((item, index) => (
        <div key={index} className="text-lg text-black mb-4 items-start">
          <h2 className="text-3xl text-black font-bold m-0 my-4">{item.groupTitle}</h2>
          {item.group !== 3 ? (
            <>
              <p className="my-3"><strong>Date:</strong> {formatDate(item.date)}</p>
              <p className="my-3"><strong>Départ:</strong> {formatTime(item.heure)}</p>
              <p className="my-3"><strong>Distance:</strong> {item.km}</p>
              <p className="my-3"><strong>Dénivelé:</strong> {item.denivele}</p>
              <p className="my-3"><strong>Vitesse Moyenne:</strong> {item.vitesse_moyenne}</p>
              <p className="my-3"><strong>Commentaire:</strong> {item.commentaire}</p>
              <a href={item.lien} target="_blank" rel="noopener noreferrer" className="underline my-8">Voir plus</a>
            </>
          ) : (
            <>
              <p className="my-3"><strong>Date:</strong> {formatDate(item.date)}</p>
              <p className="my-3"><strong>Lieu:</strong> {item.lieu}</p>
              <a href="https://cyclismefsgt31.fr/route/calendrier-route" target="_blank" rel="noopener noreferrer" className="underline my-8">Voir plus</a>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default BannerEvenement;
