import React from 'react';
import Layout from '../components/Layout';
import BannerContact from '../components/BannerContact';
import InfoContact from '../components/InfoContact';

const Contact = () => {
  return (
    <div>
      <Layout 
        Banner={<BannerContact />} 
        InfoSection={<InfoContact />} 
      />
    </div>
  );
};

export default Contact;
