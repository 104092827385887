import React from "react";
import Slider from "react-slick";

const SponsorCarousel = () => {
  const logos = [
    { src: "/picture/sponsor/secteur_cycles.jpg", url: "https://www.secteurcycles.fr" },
    { src: "/picture/sponsor/logis_hotel.png", url: "https://www.logishotels.com/fr/hotel/logis-hotel-larche-de-noe-719?partid=1535" },
    { src: "/picture/sponsor/garage_leze.png", url: "https://garage-labarthe-sur-leze.proximeca.fr" },
    { src: "/picture/sponsor/ecole_danse_francon.png", url: "https://www.ecole-danse-francon.com" },
    { src: "/picture/sponsor/fournil.png", url: "https://www.facebook.com/lefournilsaubenois/?locale=fr_FR" },
    { src: "/picture/sponsor/stilbo.png", url: "https://www.stilbo.fr" },
    { src: "/picture/sponsor/super_u.png", url: "https://www.magasins-u.com/magasin/superu-eaunes.m95133" },
  ];

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 8000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="w-full bg-black py-5">
      <div className="max-w-6xl mx-auto px-4">
        <div className="sponsor-carousel">
          <Slider {...settings}>
            {logos.concat(logos).map((logo, index) => (
              <div key={index} className="px-4">
                <div className="flex items-center justify-center h-24">
                  <a 
                    href={logo.url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="flex items-center justify-center w-full h-full"
                  >
                    <img
                      src={logo.src}
                      alt={logo.alt || "Sponsor logo"}
                      className="max-w-[110px] max-h-15 w-auto h-auto object-contain rounded mx-auto"
                    />
                  </a>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default SponsorCarousel;