import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Récupère l'année en cours

  return (
    <footer className="bg-black text-white text-center py-4">
      <p className="text-sm">
        &copy; {currentYear} Saubens Cyclo 31. Tous droits réservés.
      </p>
    </footer>
  );
};

export default Footer;
