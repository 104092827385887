import React, { useState } from 'react';

const Banner = () => {
  const [isHovered, setIsHovered] = useState(false);
  
  return (
    <section className="flex flex-col sm:flex-col lg:flex-row justify-between items-center bg-[#DD7C88] py-12 px-12">
      <div className="banner-text text-center lg:text-left">
        <h2 className="text-4xl text-black font-bold m-0 mb-4">Nous contacter</h2>
        <p className="text-lg text-black mb-4">
          N'hésitez pas à nous écrire pour toute question ou information.<br/>
          Notre équipe est à votre disposition pour vous répondre rapidement.
        </p>
      </div>
      <div 
        className="banner-image mt-8 lg:mt-0"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img
          src={isHovered ? "/picture/jersey_rear.png" : "/picture/jersey_front.png"}
          alt="Maillot Saubens Cyclo 31"
          className="h-[350px] w-auto object-contain"
        />
      </div>
    </section>
  );
};

export default Banner;
