import { useState, useEffect } from 'react';

export const useEvents = () => {
    const [events, setEvents] = useState({
        sportifData: null,
        tourismeData: null,
        fsgtData: null
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await fetch('/api/events.php', {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json'
                    },
                    mode: 'cors'  // Ajout explicite du mode CORS
                });
                
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                
                const data = await response.json();
                
                // Vérifions que nous avons bien reçu les données
                if (data) {
                    setEvents({
                        sportifData: data.sportif || null,
                        tourismeData: data.tourisme || null,
                        fsgtData: data.fsgt || null
                    });
                }
            } catch (err) {
                console.error('Erreur de chargement:', err);
                setError('Erreur de chargement des données');
            } finally {
                setLoading(false);
            }
        };

        fetchEvents();
    }, []);

    return { events, loading, error };
};