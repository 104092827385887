import React from 'react';
import Layout from '../components/Layout';
import BannerEvenement from '../components/BannerEvenement';
import InfoEvenement from '../components/InfoEvenement';
import { useEvents } from '../hooks/useEvents';

const EvenementsPage = () => {
    const { events, loading, error } = useEvents();

    if (loading) return <div className="text-center p-4">Chargement...</div>;
    if (error) return <div className="text-center p-4 text-red-600">Erreur: {error}</div>;

    return (
        <div>
            <Layout 
            Banner={<BannerEvenement 
                sportifData={events.sportifData}
                tourismeData={events.tourismeData}
                fsgtData={events.fsgtData}
            />} 
            InfoSection={<InfoEvenement />} 
            />
        </div>
    );
};

export default EvenementsPage;