import React from 'react';

const InfoSection = () => {
  const infoBlocks = [
    {
      title: "Histoire du Club",
      description: "Le Saubens Cyclo 31 voit le jour en 2001 sous l'impulsion de Pascal Gachet et Pierre Delourmes. Initialement intégré à l'Elan Multisports Saubenois, il devient indépendant en 2008, se dote de ses couleurs actuelles, et s'affilie à la FSGT."
    },
    {
      title: "Valeurs du Club",
      description: "Le club repose sur la camaraderie, le respect et la performance. Que vous soyez un cycliste confirmé ou débutant, vous trouverez chez nous un accueil convivial, un esprit d'équipe, et une passion partagée pour le vélo."
    },
    {
      title: "Nos Événements",
      description: "Nous organisons la Saubenoise chaque année en octobre, ainsi que des sorties en montagne et des week-ends à Rosas, en Espagne. Le club participe également à de nombreuses compétitions régionales et nationales."
    },
    {
      title: "Notre Objectif",
      description: "Nous visons à promouvoir le cyclisme sous toutes ses formes, à participer activement à la vie sportive locale, et à encourager nos membres à se dépasser."
    },
    {
      title: "L'Équipe du Club",
      description: (
        <>
          Voici les membres du bureau qui œuvrent pour faire vivre Saubens Cyclo 31 :<br/>
          <strong>Président</strong> : Daniel ALVAREZ<br/>
          <strong>Trésorier</strong> : Mathieu VEGLIO<br/>
          <strong>Secrétaire</strong> : Rémi COSTA<br/>
          <strong>Vice-Secrétaire</strong> : Yves BEYRIA
        </>
      )
    }
  ];

  return (
    <section className="py-12 px-12 bg-white">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2">
          {infoBlocks.map((block, index) => (
            <div
              key={index}
              className={`text-center bg-white p-6 rounded-lg ${
                index === infoBlocks.length - 1 ? 'md:col-span-2 md:max-w-2xl md:mx-auto md:w-full' : ''
              }`}
            >
              <h3 className="text-[#C84C77] mb-5 font-bold">{block.title}</h3>
              <div className="text-black mb-4">{block.description}</div>
              {block.link && (
                <a href={block.link} className="text-[#DD7C88] no-underline font-bold hover:underline">
                  {block.linkText}
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default InfoSection;