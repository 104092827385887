import React, { useState } from 'react';

const Banner = () => {
  const [isHovered, setIsHovered] = useState(false);
  
  return (
    <section className="flex flex-col sm:flex-col lg:flex-row justify-between items-center bg-[#DD7C88] py-12 px-12">
      <div className="banner-text text-center lg:text-left">
        <h2 className="text-4xl text-black font-bold m-0 mb-4">À propos du club</h2>
        <p className="text-lg text-black">
          Le Saubens Cyclo 31, c'est plus qu'un club de vélo.<br/>
          Découvrez notre histoire, nos valeurs, et l'équipe qui fait vivre cette passion pour le cyclisme.
        </p>
      </div>
      <div 
        className="banner-image lg:mt-0"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img
          src={isHovered ? "/picture/club_photo.jpeg" : "/picture/club_photo.jpeg"}
          alt="Maillot Saubens Cyclo 31"
          className="h-[350px] w-auto object-contain"
        />
      </div>
    </section>
  );
};

export default Banner;
