import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Index from './pages/Index';
import Club from './pages/Club';
import Evenement from './pages/Evenement';
import Contact from './pages/Contact';
import Activity from './pages/Activity';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/club" element={<Club />} />
        <Route path="/evenement" element={<Evenement />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/activity" element={<Activity />} />
      </Routes>
    </Router>
  );
}

export default App;
