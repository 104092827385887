import React from 'react';

const InfoSection = () => {
  const infoBlocks = [
    {
      title: "Le Club",
      description: "Découvrez l'histoire et les valeurs de notre club.",
      link: "/club",
      linkText: "En savoir plus"
    },
    // {
    //   title: "Inscription",
    //   description: "Rejoignez notre communauté de cyclistes passionnés.",
    //   link: "/inscription",
    //   linkText: "S'inscrire"
    // },
    // {
    //   title: "Espace Adhérent",
    //   description: "Accédez aux avantages réservés aux membres du club.",
    //   link: "/espace-adherent",
    //   linkText: "Connexion"
    // },
    {
      title: "Evenement",
      description: "Vous voulez venir rouler avec les roses.",
      link: "/evenement",
      linkText: "En savoir plus"
    }
  ];

  return (
    <section className="py-12 px-12 bg-white">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2">
          {infoBlocks.map((block, index) => (
            <div
              key={index}
              className={`text-center bg-white p-6 rounded-lg`}
            >
              <h3 className="text-[#C84C77] mb-5 font-bold">{block.title}</h3>
              <div className="text-black mb-4">{block.description}</div>
              {block.link && (
                <a href={block.link} className="text-[#DD7C88] no-underline font-bold hover:underline">
                  {block.linkText}
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default InfoSection;
