import React from 'react';

const InfoEvenement = () => {
  return (
    <div className="flex flex-col items-center p-4">
      <img
        src='/picture/flyer_saubenoise_2024.png'
        alt="Flyer de la Saubenoise"
        className="max-w-full h-auto rounded shadow"
      />
    </div>
  );
};

export default InfoEvenement;
