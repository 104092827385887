import React from 'react';
import Header from './Header';
import SocialBanner from './SocialBanner';
import SponsorCarousel from './SponsorCarousel';
import Footer from './Footer';

const Layout = ({ Banner, InfoSection }) => {
  return (
    <div>
      <Header />
      {Banner}
      <SocialBanner />
      {InfoSection}
      <SponsorCarousel />
      <Footer />
    </div>
  );
};

export default Layout;