import React from 'react';
import Layout from '../components/Layout';
import BannerClub from '../components/BannerClub';
import InfoClub from '../components/InfoClub';

const Club = () => {
  return (
    <div>
      <Layout 
        Banner={<BannerClub />} 
        InfoSection={<InfoClub />} 
      />
    </div>
  );
};

export default Club;
