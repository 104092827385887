import React, { useState } from 'react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-white text-black py-5 px-5">
      {/* Container principal */}
      <div className="flex justify-between items-center">
        {/* Logo */}
        <h1 className="text-[28px] m-0 font-bold">SAUBENS 31</h1>

        {/* Bouton hamburger - visible uniquement sur mobile */}
        <button 
          onClick={toggleMenu}
          className="md:hidden p-2"
          aria-label="Toggle menu"
        >
          <div className="w-6 h-0.5 bg-black mb-1.5"></div>
          <div className="w-6 h-0.5 bg-black mb-1.5"></div>
          <div className="w-6 h-0.5 bg-black"></div>
        </button>

        {/* Navigation - version desktop */}
        <nav className="hidden md:block">
          <ul className="list-none m-0 p-0 flex">
            <li className="ml-5">
              <a href="/" className="text-black no-underline text-base px-2.5 py-1.5 rounded hover:bg-[#DD7C88]">
                Accueil
              </a>
            </li>
            <li className="ml-5">
              <a href="/club" className="text-black no-underline text-base px-2.5 py-1.5 rounded hover:bg-[#DD7C88]">
                Le Club
              </a>
            </li>
          {/* <li className="ml-5">
            <a href="/inscription" className="text-black no-underline text-base px-2.5 py-1.5 rounded hover:bg-[#DD7C88]">
              Inscription
            </a>
          </li> */}
          <li className="ml-5">
            <a href="/evenement" className="text-black no-underline text-base px-2.5 py-1.5 rounded hover:bg-[#DD7C88]">
              Evenement
            </a>
          </li>
          <li className="ml-5">
            <a href="/contact" className="text-black no-underline text-base px-2.5 py-1.5 rounded hover:bg-[#DD7C88]">
              Contact
            </a>
          </li>
          {/* <li className="ml-5">
            <a href="/espace-adherent" className="text-black no-underline text-base px-2.5 py-1.5 rounded hover:bg-[#DD7C88]">
              Espace Adhérent
            </a>
          </li> */}
          </ul>
        </nav>
      </div>

      {/* Menu mobile - s'affiche uniquement quand isMenuOpen est true */}
      <nav className={`${isMenuOpen ? 'block' : 'hidden'} md:hidden mt-4`}>
        <ul className="list-none m-0 p-0 flex flex-col">
          <li className="mb-2">
            <a 
              href="/" 
              className="block text-black no-underline text-base px-2.5 py-1.5 rounded hover:bg-[#DD7C88]"
              onClick={() => setIsMenuOpen(false)}
            >
              Accueil
            </a>
          </li>
          <li className="mb-2">
            <a 
              href="/club" 
              className="block text-black no-underline text-base px-2.5 py-1.5 rounded hover:bg-[#DD7C88]"
              onClick={() => setIsMenuOpen(false)}
            >
              Le Club
            </a>
          </li>
          <li className="mb-2">
            <a 
              href="/evenement" 
              className="block text-black no-underline text-base px-2.5 py-1.5 rounded hover:bg-[#DD7C88]"
              onClick={() => setIsMenuOpen(false)}
            >
              Evenement
            </a>
          </li>
          <li className="mb-2">
            <a 
              href="/contact" 
              className="block text-black no-underline text-base px-2.5 py-1.5 rounded hover:bg-[#DD7C88]"
              onClick={() => setIsMenuOpen(false)}
            >
              Contact
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;