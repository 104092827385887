import React, { useState } from 'react';

const getNextSunday = () => {
  const today = new Date();
  const nextSunday = new Date();
  nextSunday.setDate(today.getDate() + ((7 - today.getDay()) % 7 || 7));
  return nextSunday.toISOString().split('T')[0];
};

const InfoSection = () => {
  
  const [formData, setFormData] = useState({
    date: getNextSunday(),
    heure: '09:00',
    groupe: '1',
    km: '',
    denivele: '',
    vitesse_moyenne: '28',
    commentaire: 'rendez-vous place la mairie de Saubens',
    lien: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
      vitesse_moyenne: name === 'groupe' ? (value === '1' ? '28' : '25') : prevState.vitesse_moyenne
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/set_activites.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      
      if (response.ok) {
        alert('Activité ajoutée avec succès !');
        window.location.reload();
      } else {
        alert("Erreur lors de l'ajout de l'activité.");
      }
    } catch (error) {
      console.error('Erreur:', error);
      alert("Erreur lors de l'ajout de l'activité.");
    }
  };

  return (
    <div className="p-6 max-w-lg mx-auto bg-white rounded-xl">
      <h2 className="text-xl font-bold mb-4">Ajouter une Activité</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <input type="date" name="date" value={formData.date} onChange={handleChange} className="w-full p-2 border rounded" required />
        <input type="time" name="heure" value={formData.heure} onChange={handleChange} className="w-full p-2 border rounded" required />
        <select name="groupe" value={formData.groupe} onChange={handleChange} className="w-full p-2 border rounded">
          <option value="1">Groupe 1</option>
          <option value="2">Groupe 2</option>
        </select>
        <input type="number" name="km" value={formData.km} onChange={handleChange} placeholder="Distance (km)" className="w-full p-2 border rounded" required />
        <input type="number" name="denivele" value={formData.denivele} onChange={handleChange} placeholder="Dénivelé (m)" className="w-full p-2 border rounded" required />
        <input type="number" name="vitesse_moyenne" value={formData.vitesse_moyenne} readOnly className="w-full p-2 border rounded" />
        <input type="text" name="commentaire" value={formData.commentaire} onChange={handleChange} placeholder="Commentaire" className="w-full p-2 border rounded" required />
        <input type="url" name="lien" value={formData.lien} onChange={handleChange} placeholder="Lien" className="w-full p-2 border rounded" required />
        <button type="submit" className="w-full text-[#DD7C88] font-bold px-4 py-2 rounded hover:underline">Enregistrer</button>
      </form>
    </div>
  );
};

export default InfoSection;
