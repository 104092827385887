import React from 'react';

const SocialBanner = () => {
  const socials = [
    { icon: "/picture/social/instagram-icon.png", url: "https://www.instagram.com/sc31_saubens_cyclo_31/" },
    { icon: "/picture/social/strava-icon.png", url: "https://www.strava.com/clubs/saubens-cyclo-31-34239?hl=fr-FR" },
    { icon: "/picture/social/facebook-icon.png", url: "https://www.facebook.com/SC31SaubensCyclo31" }
  ];

  return (
    <div className="bg-[#C84C77] py-5 text-center">
      <div className="social-icons">
        {socials.map((social, index) => (
          <a 
            key={index}
            href={social.url}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block mx-[30px]"
          >
            <img 
              src={social.icon} 
              alt="Social media" 
              className="h-10 w-auto inline-block transition-transform duration-300 hover:scale-110"
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default SocialBanner;